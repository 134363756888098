<template>
  <div class="mt-2">
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <i class="far fa-user"></i>
      <h4 class="mb-0 ml-5">Personal Information</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>
        <!-- Field: Birth Date -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Birth Date" label-for="birth-date">
            <flat-pickr
              v-model="userDataInfo.date_birth"
              class="form-control"
              :config="config"
              placeholder="YYYY-MM-DD"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Mobile -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Mobile" label-for="mobile">
            <b-form-input id="mobile" v-model="formatPhone" />
          </b-form-group>
        </b-col>

        <!-- Field: identification -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Identification" label-for="identification">
            <b-form-input
              id="identification"
              v-model="userDataInfo.identification"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Website -->
        <!-- <b-col cols="12" md="6" lg="4">
          <b-form-group label="Website" label-for="website">
            <b-form-input id="website" v-model="userDataInfo.website" />
          </b-form-group>
        </b-col> -->

        <!-- Field: Language -->
        <!-- <b-col cols="12" md="6" lg="4">
          <b-form-group label="Language" label-for="language">
            <v-select
              v-model="userDataInfo.language"
              :options="languageOptions"
              :clearable="false"
              input-id="language"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Gender -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Form.Gender')"  label-for="gender" label-class="mb-1">
            <b-form-radio-group
              id="gender"
              v-model="userDataInfo.gender"
              :options="genderOptions"
              value="male"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Phone Options -->
        <!-- <b-col cols="12" md="6" lg="4">
          <b-form-group
            label="Phone Options"
            label-for="contact-options"
            label-class="mb-1"
          >
            <b-form-checkbox-group
              id="contact-options"
              v-model="userDataInfo.contactOptions"
              :options="contactOptionsOptions"
            />
          </b-form-group>
        </b-col> -->
      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <i class="fas fa-directions"></i>
        <h4 class="mb-0 ml-5">Address</h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">
        <!-- Field: Address Line 1 -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Address Line 1" label-for="address-line-1">
            <b-form-input id="address-line-1" v-model="userDataInfo.address" />
          </b-form-group>
        </b-col>

        <!-- Field: Address Line 2 -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Address Line 2"
            label-for="address-line-2"
          >
            <b-form-input
              id="address-line-2"
              v-model="userDataInfo.addressLine2"
              placeholder="Los Santos"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: State -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="State" label-for="state">
            <b-form-input
              id="state"
              v-model="userDataInfo.state"
              placeholder="Manhattan"
            />
          </b-form-group>
        </b-col>

        <!-- Field: City -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Form.City')" label-for="city">
            <b-form-input id="city" v-model="userDataInfo.city" />
          </b-form-group>
        </b-col>

        <!-- Field: Postcode -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Postcode" label-for="postcode">
            <b-form-input
              id="postcode"
              v-model="userDataInfo.zip_code"
              type="number"
              placeholder="597626"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Country -->
        <!-- <b-col cols="12" md="6" lg="4">
          <b-form-group label="Country" label-for="country">
            <b-form-input
              id="country"
              v-model="userDataInfo.country"
              placeholder="United States"
            />
          </b-form-group>
        </b-col> -->
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="saveChanges"
            :disabled="loading"
          >
            <span v-if="!loading">Save Changes</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
          <b-button
            variant="outline-danger"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { computed, ref } from "@vue/composition-api";

import axiosU from "@/core/services/apiInsurance/admin/user";
import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";

export default {
  components: {
    flatPickr,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    ToastNotification,
    SpinnerLoading,
  },
  props: {
    userData: {
      type: Object,
      required: false,
    },
  },
  setup(props, { refs }) {
    const userDataInfo = ref(props.userData);

    const genderOptions = [
      { text: "Male", value: "male" },
      { text: "Female", value: "female" },
    ];

    const config = {
      altInput: true,
      altFormat: "m-d-Y",
      dateFormat: "m-d-Y",
      allowInput: true,
    };

    const formatPhone = computed({
      get() {
        let { phone } = userDataInfo.value;
        if (phone) {
          phone = phone.toString().replace(/\D/g, "");
          const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
          if (match) {
            phone = `(${match[1]}${match[2] ? "" : ""}) ${match[2]}${
              match[3] ? "-" : ""
            }${match[3]}`;
          }
          return phone;
        } else {
          return "";
        }
      },
      set(val) {
        userDataInfo.value.phone = val;
      },
    });

    const loading = ref(false);
    const saveChanges = () => {
      loading.value = true;
      const phone = isNaN(this.userDataInfo.value.phone)
        ? this.userDataInfo.value.phone.replace(/[() -]/g, "")
        : this.userDataInfo.value.phone;
      axiosU
        .userUpdate(props.userData.id, { ...props.userData, phone })
        .then(() => {
          loading.value = false;
          refs.toast.success("User updated successfully");
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          loading.value = false;
          refs.toast.error("Error updating user");
        });
    };

    return {
      userDataInfo,
      formatPhone,
      config,
      genderOptions,
      loading,
      saveChanges,
    };
  },
};
</script>