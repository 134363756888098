<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar ref="previewEl" :src="userData.avatar" :text="avatarText(userData.name)" :variant="`light-dark`"
          size="90px" rounded />
      </template>
      <h4 class="mb-1">{{ userData.name }} {{ userData.lastname }}</h4>
      <div class="d-flex flex-wrap flex-column">
        <b-form-file id="file-small" size="sm" class="my-1 w-25" accept="image/png, image/jpeg" v-model="photoSelected"
          @change="onFileChange($event)" />
        <div>
          <b-button variant="primary" @click="saveChangesImage()">
            <span class="d-none d-sm-inline">Update</span>
            <i class="fas fa-pen d-inline d-sm-none"></i>
          </b-button>
        </div>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form autocomplete="nope">
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Name')" label-for="name">
            <b-form-input id="name" v-model="userData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Last Name')" label-for="last-name">
            <b-form-input id="last-name" v-model="userData.lastname" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="userData.email" type="email" disabled />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Status')" label-for="user-status">
            <treeselect id="user-status" v-model="userData.status" :options="statusOptions" :normalizer="normalizer" />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col cols="12" md="4">
          <b-form-group label="User Role" label-for="user-role">
            <treeselect id="user-status" v-model="userData.roles" :options="rolesData" :normalizer="normalizer" />
          </b-form-group>
        </b-col>
        <!-- password -->
        <b-col cols="12" md="4">
          <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
            <b-form-group label="Password" label-for="password">
              <b-input-group>
                <b-form-input id="password" v-model="userData.password" :type="passwordFieldType"
                  class="form-control-merge" name="hidden" autocomplete="new-password" />
                <b-input-group-append is-text>
                  <i :class="passwordToggleIcon" @click="togglePasswordVisibility"></i>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <!-- Field: Email -->
      </b-row>
    </b-form>
    <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="saveChanges" :disabled="loading">
      <span v-if="!loading">Save Changes</span>
      <span v-else>
        <SpinnerLoading />
      </span>
    </b-button>
    <b-button variant="outline-secondary" type="reset" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
      Reset
    </b-button>

    <!-- FACILITY TABLE -->
    <b-card class="text-center">
      <b-card-header class="p-1 justify-content-center">
        <b-card-title class="font-medium-2">
          <i class="fas fa-hospital align-middle ml-2"></i>
          <span class="align-middle ml-50">Facilities</span>
        </b-card-title>
      </b-card-header>
      <treeselect id="mySelect" v-model="userData.facilities" :options="facilityOptions" :normalizer="normalizer"
        multiple />
      <b-col cols="12">
        <b-button variant="primary" class="mt-2" :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="saveChangesFacility" :disabled="loading">
          <span v-if="!loading">Save Changes</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
      </b-col>
    </b-card>
    <!-- PERMISSION TABLE -->
    <!-- <b-card no-body class="border mt-1 text-center">
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon icon="LockIcon" size="18" />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table striped responsive class="mb-0" :items="permissionsData">
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
      <b-col cols="12">
        <b-button
          variant="primary"
          class="mt-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="saveChanges"
          :disabled="loading"
        >
          <span v-if="!loading">Save Changes</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
      </b-col>
    </b-card> -->

    <!-- Action Buttons -->

    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { avatarText } from "@/core/utils/filter";

import axios from 'axios'
import axiosU from "@/core/services/apiInsurance/admin/user";
import axiosF from "@/core/services/apiInsurance/admin/facilities";

import SpinnerLoading from "@/components/SpinnerLoading";
import ToastNotification from "@/components/ToastNotification";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormFile,
    SpinnerLoading,
    ToastNotification,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    rolesData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password"
        ? "fas fa-eye"
        : "fas fa-eye-slash";
    },
  },
  mounted() {
    this.getFacilityOptions();
  },
  data() {
    return {
      statusOptions: [
        { name: "Active", id: true },
        { name: "Inactive", id: false },
      ],
      facilityOptions: [],
      permissionsData: [
        {
          module: "Banners",
          index: true,
          read: true,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: "Staff",
          read: false,
          write: true,
          create: false,
          delete: false,
        },
        {
          module: "Author",
          read: true,
          write: false,
          create: true,
          delete: false,
        },
        {
          module: "Contributor",
          read: false,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: "User",
          read: false,
          write: false,
          create: false,
          delete: true,
        },
      ],
      fileContents: null,
      file: null,
      tags: "browser-upload",
      photoSelected: null,
      loading: false,
      passwordFieldType: "password",
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  methods: {
    avatarText(name) {
      return avatarText(name);
    },
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    onFileChange(event) {
      //returns an array of files even though multiple not used
      this.file = event.target.files[0];
      this.filesSelected = event.target.files.length;
      this.loginImage = true;
      this.photoSelected = URL.createObjectURL(this.file);
    },
    prepareFormData() {
      this.formData = new FormData();
      this.formData.append("upload_preset", process.env.VUE_APP_PRESET_CLOUDINARY);
      this.formData.append("tags", this.tags); // Optional - add tag for image admin in Cloudinary
      this.formData.append("file", this.fileContents);
    },
    viewImage(image) {
      this.viewImageBoolean = true;
      this.previewImage = image.target.src;
    },
    getFacilityOptions() {
      axiosF.facilityList(20).then(({ data }) => {
        const facilityList = data.map((item) => {
          const facilities_id = item.id;
          return {
            facilities_id,
            ...item,
          };
        });
        this.facilityOptions = facilityList;
      });
    },
    saveChangesImage() {
      if (this.loginImage) {
        localStorage.setItem("imageLogin", this.photoSelected);
      }
      let reader = new FileReader();
      // attach listener to be called when data from file
      reader.addEventListener(
        "load",
        function () {
          this.fileContents = reader.result;
          this.prepareFormData();
          let cloudinaryUploadURL = `${process.env.VUE_APP_URL_CLOUDINARY}upload`;
          let requestObj = {
            url: cloudinaryUploadURL,
            method: "POST",
            data: this.formData,
            onUploadProgress: function (progressEvent) {
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              );
              //bind "this" to access vue state during callback
            }.bind(this),
          };
          //show progress bar at beginning of post
          axios(requestObj)
            .then((response) => {
              this.results = response.data;
              this.userData.avatar = this.results.url;
            })
            .catch((error) => {
              this.errors.push(error);
            })
            .finally(() => {
              setTimeout(function () {
                this.saveChanges()
              }.bind(this), 1000);
            });
        }.bind(this),
        false
      );
      if (this.file && this.file.name) {
        reader.readAsDataURL(this.file);
      }

    },
    saveChanges() {
      axiosU
        .userUpdate(this.userData.id, this.userData)
        .then(() => {
          this.$refs.toast.success("User updated successfully");
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          this.$refs.toast.error("Error updating user");
        });
    },
    saveChangesFacility() {
      const data = {
        facilities: this.userData.facilities,
      };
      axiosU
        .userUpdateFacilities(this.userData.id, data)
        .then(() => {
          this.$refs.toast.success("User updated successfully");
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          this.$refs.toast.error("Error updating user");
        });
    },
  },
};
</script>
